// Layout styles

.l-container {
  margin: 0 auto;
  max-width: $l-max-width;
  width: 90%;

  &--narrow {
    @include respond-to(em(900)) {
      width: 60%;
    }
  }
}

.l-section {
  padding: em(40) 0;
}

.section-divider {
  position: relative;
  background-color: #3d4042;
  box-shadow: 0 0 0px 4px #3d4042, 0 3px 6px 4px rgba(0,0,0,0.4);
  border-top: 1px dashed #5e7f7d;
  border-bottom: 1px dashed #5e7f7d;

  .lt-ie9 & {
    border-top: 0;
    border-bottom: 0;
  }
}

// Section styles

[role="banner"] {
  background: url(../images/city.jpg) no-repeat center bottom #bb4039;

  .workshop & {
    background-image: url(../images/city-purple.jpg);
    background-color: #663399;
  }
}

[role="contentinfo"] {
  padding: 30px 0 90px;
  text-align: center;
  background: url(../images/footer-bg.png) repeat-x bottom #bb4039;
  border-bottom: 1px solid #2a353e;

  .workshop & {
    background-image: url(../images/footer-bg-purple.png);
    background-color: #663399;
  }
}

.site-info {
  padding: 20px 0;
}

.section {
  &--light {
    background-color: $section-light;
    color: #333;

    a {
      color: #06786f;

      &:hover,
      &:focus {
        color: darken(#06786f, 10%);
      }
    }
  }

  &--callout {
    background-color: $section-callout;
  }

  &--dark {
    background-color: $section-dark;
  }

  &--x-dark {
    background-color: $section-x-dark;
  }
}

.row {
  &:after {
    display: block;
    height: 0;
    clear: both;
    font-size: 0;
    content: " ";
    visibility: hidden;
  }
}

.info {
  padding: 30px 0;
  color: #333;
  background-color:#eeecd2
}

.section-detail {
  margin: 0 0 30px 0;
  padding-bottom: 30px;
  border-bottom: 1px dashed #cccccc;

  > p {
    margin-bottom: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.map {
  position: relative;
  margin: 20px 0 20px;
  overflow: hidden;
  max-width: 100%;
}

#map_canvas {
  height: 300px;

  img {
    max-width: inherit;
  }

  * {
    @include box-sizing(content-box);
  }
}

.map-info {
  color: #333;
}

.no-js .map {
  display: none;
}

.js .static-map {
  display: none;
}

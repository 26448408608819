// Tile styles

.tile {
  &-container {
    display: flex;
    flex-wrap: wrap;

    // Change this property to fit the needs of the design
    // For example, use 'space-around' to evenly distrubute
    // the tiles in the container.
    justify-content: center;

    &--center {
      align-items: center;
    }
  }

  &__item {
    flex: 1 1 400px;
    text-align: center;
  }

  &__content {
    @include respond-to(em(900)) {
      padding: 20px;
    }
  }

  &__avatar {
    width: 300px;
    border-radius: 300px;
    border: 10px solid #fff;
    box-shadow: 0 0 0 5px rgba(0,0,0,.2);
  }
}

// Navigation styles

.main-nav {
  position: relative;
  margin: 4px 0 0 0;
  text-align: center;
  background-color: #3d4042;
  box-shadow: 0 0 0px 4px #3d4042, 0 3px 6px 4px rgba(0,0,0,0.4);
  border-top: 1px dashed #5e7f7d;
  border-bottom: 1px dashed #5e7f7d;

  .lt-ie9 & {
    border-top: 0;
    border-bottom: 0;
  }

  > li {
    padding: 12px 0 10px 0;
    font-family: $title-font-bold;
    font-weight: 900;
    text-transform: uppercase;

    .wf-loading & {
      visibility: hidden;
    }
  }

  a {
    color: #fff;

    &:hover,
    &:focus {
      text-decoration: none;
      color: darken(#fff, 20%);
    }
  }

  .active {
    text-decoration: none;
    color: darken(#fff, 20%);
  }
}

.footer-nav {
  margin: 10px;
  padding: 0;
  font-family: $title-font-bold;
  font-weight: 900;
  text-transform: uppercase;

  li {
    .wf-loading & {
      visibility: hidden;
    }
  }

  a {
    color: #fff;

    &:hover,
    &:focus {
      text-decoration: none;
      color: darken(#fff, 20%);
    }
  }
}

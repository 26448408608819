// FORM STYLES
// -----------

form {
  display: block;
}

input {
  padding: 10px 18px;
  margin-right: 30px;
  outline: 0;
  font-size: em(18);
  font-family: $title-font-normal;
  border: 0;

   @include placeholder {
    color: #999;
    font-style: italic;
  }

  &:focus {
    background-color: #e3e3e3;
  }

  @media only screen and (max-width: em(650)) {
    margin-bottom: 20px;;
    width: 100%;

    &.button {
      width: auto;
    }
  }
}

label {
  margin-bottom: 10px;
}

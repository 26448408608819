// BUTTON STYLES

.button {
  display: inline-block;
  margin: 0;
  padding: em(15) em(30);
  text-transform: uppercase;
  text-decoration: none;
  font-family: $title-font-normal;
  font-size: em(18);
  color: #fff;
  background-color: #139589;
  border-radius: 6px;
  border: 0;

  &:hover,
  &:focus {
    background-color: shade(#139589, 10%);
    text-decoration: none;
  }

  .speakers &,
  .info & {
    color: #fff;

    &:hover,
    &:focus {
      color: #fff;
    }
  }

  &--small {
    padding: 10px 18px;
    font-size: em(18);
  }

  &--main {
    margin: 30px 0 0;
  }
}

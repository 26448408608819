// Image styles

.img-border {
  border: 4px solid #fff;
  box-shadow: 0 2px 3px rgba(0,0,0,.3);
}

.library {
  @media screen and (max-width: em(768)) {
    display: none;
  }
}

// Content styles

// Headlines

%headline {
  font-family: $title-font-normal;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: .5em;
  line-height: 1.2;

  .wf-loading & {
    visibility: hidden;
  }
}

.primary-headline {
  @extend %headline;
  font-size: em(58);
  margin-bottom: .2em;

  @include respond-to(em(800), max-width) {
    font-size: em(40);
  }

  @include respond-to(em(600), max-width) {
    font-size: em(30);
  }
}

.page-headline {
  margin-bottom: 40px;
}

.secondary-headline {
  @extend %headline;
  font-size: em(32);

  @include respond-to(em(600), max-width) {
    font-size: em(24);
  }
}

.tertiary-headline {
  @extend %headline;
  margin-top: 1.4em;
  font-size: em(24);

  @include respond-to(em(600), max-width) {
    font-size: em(16);
  }
}

.section-title {
  margin: 0;
  padding: 12px 0 10px 0;
  font-family: $title-font-bold;
  font-weight: 900;
  font-size: 100%;
  letter-spacing: .1em;
  line-height: 1.1;
  text-align: center;
  text-transform: uppercase;
  color: #fff;

  .wf-loading & {
    visibility: hidden;
  }
}

.emphasized {
  font-family: $title-font-normal;
  font-size: em(24);
  text-transform: uppercase;

  @include respond-to(em(600), max-width) {
    font-size: em(20);
  }
}

.scale {
  text-transform: none;
}

.w-meta {
  margin-bottom: 0;

  + .w-meta {
    margin-top: 0;
  }
}

// Logo styles

.logo {
  margin: 0;
  padding: em(25, 122) 0;
  text-align: center;
  font-family: $title-font-bold;
  font-size: em(112);
  font-weight: 800;
  letter-spacing: normal;
  line-height: 1;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 0 0px 3px rgba(0,0,0,0.5);

  a {
    color: #fff;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  @include respond-to(em(800), max-width) {
    padding: 20px 0;
    font-size: em(84);
  }

  @include respond-to(em(600), max-width) {
    padding: 20px 0;
    font-size: em(56);
  }
}

// Text styles

.txt-left {
  text-align: left;
}

.txt-right {
  text-align: right;
}

.txt-center {
  text-align: center;
}

// Vcard styles

.fn {
  .section--dark & {
    color: #fff;
    text-decoration: none;

    &:hover,
    &:focus {
      color: #fff;
      text-decoration: underline;
    }
  }
}

.vevent {
  padding: 1.5em;

  time,
  .summary {
    display: inline-block;
    margin: 0;
    font-family: $title-font-normal;
    font-weight: 900;
    letter-spacing: .12em;

    .wf-loading & {
      visibility: hidden;
    }

    em {
      font-family: $serif-font;
      font-weight: normal;
      text-transform: none;

      &:before {
        margin: 0 .5em 0 .2em;
        content: "\2022";
      }

      @media screen and (max-width: 650px) {
        display: block;
        margin-top: .5em;

        &:before {
          margin: 0;
          content: none;
        }
      }
    }
  }

  .summary {
    margin-left: 2%;
    padding-left: 4%;
    border-left: 1px solid rgba(255,255,255,.5);

    @media screen and (max-width: em(905)) {
      margin-left: 0;
      padding-left: 0;
      border-left: 0;
    }
  }

  a {
    color: #fff;
  }

  .time {
    display: inline-block;
    width: 15%;
    text-align: center;

    @media screen and (max-width: em(905)) {
      display: block;
      margin-bottom: .5em;
      width: auto;
      text-align: left;
    }
  }

  &:nth-child(even) {
    background-color: #15a095;
  }
}

// List styles

.horizontal-list {
  margin: 0;
  padding: 0;

  > li {
    display: inline-block;

    &:before {
      margin: 1em;
      content: "\2022";
    }

    &:first-child:before {
      margin: 0;
      content: none;
    }
  }
}

.sponsor-list {
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    margin: 20px;
  }
}

.sponsor-list__item {
  display: inline-block;
  vertical-align: middle;
}

.list-callout {
  margin: 0 0 0 1em;
  padding: 0;

  > li {
    margin-bottom: 1em;
  }
}

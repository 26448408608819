// Base settings for the site

// Colors

$section-divider-bg: #3d4042;
$section-light: #eeecd2;
$section-callout: #039589;
$section-dark: #40515e;
$section-x-dark: #242424;

// Fonts

$title-font-bold: "Open Sans", $helvetica;
$title-font-normal: "Open Sans", $helvetica;
$sans-serif-font: "Open Sans", $helvetica;
$serif-font: $georgia;

// Layout

$l-max-width: em(1000);

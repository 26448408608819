// Media object

.media,
.media-body {
  overflow: hidden;
  *overflow: visible;
  zoom: 1;
}

.media-object {
  display: block;
}

.media {
  > .pull-left {
    float: left;
    margin-right: 20px;
  }

  > .pull-right {
    float: right;
    margin-left: 20px;
  }
}

.media-body {
  > * {
    margin-top: 0;
  }
}

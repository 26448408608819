// Base styles

* {
  @include box-sizing(border-box);
}

html {
  background-color: $section-divider-bg;
}

body {
  font-family: $sans-serif-font;
  line-height: 1.5;
  color: #fff;
}

// Link styles

a {
  color: #82e7df;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

// Images

img {
  max-width: 100%;
}

figure img {
  margin-bottom: 10px;
}

figcaption {
  font-style: italic;
  text-align: center;
}

.is-hidden-visually {
  position: absolute !important;
  padding: 0;
  overflow: hidden;
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  border: 0;
}

hr {
  margin: 30px 0;
  border-top: 1px dashed #ccc;
  border-bottom: 0;
  border-right: 0;
  border-left: 0;
  height: 1px;
  background-color: transparent;
}

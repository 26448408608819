// Link styles

.ticket {
  display: inline-block;
  width: 186px;
  height: 77px;
  text-indent: -9999em;
  background: url(../images/register-full.png) no-repeat 0 0;

  + p {
    margin-top: .5em;
  }
}

.speakers,
.info {
  a {
    color: #06786f;

    &:hover,
    &:focus {
      color: #06554f;
    }
  }
}

.bg-icon {
  padding-left: 26px;
  background-repeat: no-repeat;
  background-position: left center;
}

.bg-icon--slides {
  @include retina-image("../images/slide-icon", 16px 13px)
}

.bg-icon--link {
  @include retina-image("../images/link-icon", 16px 13px)
}
